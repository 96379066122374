/**
 * = Shapes
 */

.avatar-sm {
  width: $avatar-sm-y;
  height: $avatar-sm-x;
}

.avatar-md {
  width: $avatar-md-y;
  height: $avatar-md-x;
}

.avatar-lg {
  width: $avatar-lg-y;
  height: $avatar-lg-x;
}
