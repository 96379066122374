@mixin popover-variant($background) {
  background-color: $background;

  .popover-header {
    background-color: $background;
    color: color-yiq($background);
  }

  .popover-body {
    color: color-yiq($background);
  }
  .popover-header {
    border-color: rgba($background, 0.2);
  }
  &.bs-popover-top {
    .arrow::after {
      border-top-color: $background;
    }
  }
  &.bs-popover-right {
    .arrow::after {
      border-right-color: $background;
    }
  }
  &.bs-popover-bottom {
    .arrow::after {
      border-bottom-color: $background;
    }
  }
  &.bs-popover-left {
    .arrow::after {
      border-left-color: $background;
    }
  }
}
