body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "segoe ui", roboto, oxygen,
    ubuntu, cantarell, "fira sans", "droid sans", "helvetica neue", Arial,
    sans-serif;
  font-size: 16px;
}

.login {
  background-color: #ffffff;
}

.login form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 40px;
}
.login form div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.login form label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #B04532;
  color: #ffffff;
  border-radius: 12px 0 0 12px;
}
.login form input[type="text"],
.login form input[type="date"],
.login form input[type="checkbox"],
.login form select,
.login form input[type="number"],
.login form input[type="datetime-local"] {
  width: 100%;
  height: 50px;
  border: 1px solid #dee0e4;
  margin-bottom: 20px;
  padding: 0 15px;
  border-radius: 0 12px 12px 0;
}

.login form input[type="checkbox"] {
  appearance: none;
}

.login form input[type="checkbox"]:checked {
  padding-top: 10px;
}

.login form input[type="checkbox"]:checked:after {
  content: '✔';
}

.login form input[type="submit"] {
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  background-color: #0C5850;
  border: 0;
  cursor: pointer;
  font-weight: bold;
  color: #ffffff;
  border-radius: 12px;
  transition: background-color 0.2s;
}
.login form input[type="submit"]:hover {
  background-color: #0a4640;
  transition: background-color 0.2s;
}

#logout-form {
  display: inline-block;
}

#logout {
  background: none;
  border: none;
  padding: 0;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  cursor: pointer;
}

.user-dropdown.hidden{
  display: none;
}

textarea {
  resize: none;
}

.logo-side-bar {
  max-width: 50%;
  margin: 0 25% 5% 25%;
}