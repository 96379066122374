.month {
  margin: 0px 20px;
  display: inline-block;
  width: calc(100% - 40px);
}
rect {
  fill: none;
  stroke: #eee;
}
text {
  text-anchor: middle;
  font-size: 14px;
}
.day.past text {
  fill: #aaa;
}
.day.today rect {
  fill: #222;
}
.day.today text {
  fill: #fff;
}
.day-of-week{
  font-weight:bold ;
  font-size: 1.08em;
}
.outline {
  fill: none;
  stroke: #888;
}

@media only screen and (max-width: 574px) {
  .month {
    margin: 0px;
    width: 100%;
  }
}
